/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  Safe_to_l2_migration,
  Safe_to_l2_migrationInterface,
} from "../../../../../../@safe-global/safe-deployments/dist/assets/v1.4.1/Safe_to_l2_migration";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "singleton",
        type: "address",
      },
    ],
    name: "ChangedMasterCopy",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "enum Enum.Operation",
        name: "operation",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "safeTxGas",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "baseGas",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "gasPrice",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "gasToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address payable",
        name: "refundReceiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "signatures",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "additionalInfo",
        type: "bytes",
      },
    ],
    name: "SafeMultiSigTransaction",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "initiator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "owners",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "threshold",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "initializer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "fallbackHandler",
        type: "address",
      },
    ],
    name: "SafeSetup",
    type: "event",
  },
  {
    inputs: [],
    name: "MIGRATION_SINGLETON",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "l2Singleton",
        type: "address",
      },
      {
        internalType: "address",
        name: "fallbackHandler",
        type: "address",
      },
    ],
    name: "migrateFromV111",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "l2Singleton",
        type: "address",
      },
    ],
    name: "migrateToL2",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class Safe_to_l2_migration__factory {
  static readonly abi = _abi;
  static createInterface(): Safe_to_l2_migrationInterface {
    return new Interface(_abi) as Safe_to_l2_migrationInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): Safe_to_l2_migration {
    return new Contract(
      address,
      _abi,
      runner
    ) as unknown as Safe_to_l2_migration;
  }
}
