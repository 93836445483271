/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  Safe_to_l2_setup,
  Safe_to_l2_setupInterface,
} from "../../../../../../@safe-global/safe-deployments/dist/assets/v1.4.1/Safe_to_l2_setup";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "singleton",
        type: "address",
      },
    ],
    name: "ChangedMasterCopy",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "l2Singleton",
        type: "address",
      },
    ],
    name: "setupToL2",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class Safe_to_l2_setup__factory {
  static readonly abi = _abi;
  static createInterface(): Safe_to_l2_setupInterface {
    return new Interface(_abi) as Safe_to_l2_setupInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): Safe_to_l2_setup {
    return new Contract(address, _abi, runner) as unknown as Safe_to_l2_setup;
  }
}
