/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  Safe_migration,
  Safe_migrationInterface,
} from "../../../../../../@safe-global/safe-deployments/dist/assets/v1.4.1/Safe_migration";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "safeSingleton",
        type: "address",
      },
      {
        internalType: "address",
        name: "safeL2Singleton",
        type: "address",
      },
      {
        internalType: "address",
        name: "fallbackHandler",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "singleton",
        type: "address",
      },
    ],
    name: "ChangedMasterCopy",
    type: "event",
  },
  {
    inputs: [],
    name: "MIGRATION_SINGLETON",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SAFE_FALLBACK_HANDLER",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SAFE_L2_SINGLETON",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SAFE_SINGLETON",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "migrateL2Singleton",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "migrateL2WithFallbackHandler",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "migrateSingleton",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "migrateWithFallbackHandler",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class Safe_migration__factory {
  static readonly abi = _abi;
  static createInterface(): Safe_migrationInterface {
    return new Interface(_abi) as Safe_migrationInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): Safe_migration {
    return new Contract(address, _abi, runner) as unknown as Safe_migration;
  }
}
