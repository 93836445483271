export const adjectivesDict = `
admirable
energetic
lucky
affable
enjoyable
magnificent
affectionate
enthusiastic
marvelous
agreeable
euphoric
meritorious
amazing
excellent
merry
amiable
exceptional
amused
excited
nice
amusing
extraordinary
noble
animated
exultant
outstanding
appreciative
fabulous
overjoyed
astonishing
faithful
passionate
authentic
fantastic
peaceful
believable
fervent
placid
benevolent
fortunate
pleasant
blissful
friendly
pleasing
bouncy
fun
pleasurable
brilliant
genuine
positive
bubbly
glad
praiseworthy
buoyant
glorious
prominent
calm
good
proud
charming
relaxed
cheerful
reliable
cheery
gracious
respectable
clever
grateful
sharp
comfortable
great
sincere
comical
happy
spirited
commendable
heartfelt
splendid
confident
honest
superb
congenial
honorable
superior
content
hopeful
terrific
cordial
humorous
thankful
courteous
incredible
tremendous
dedicated
inspirational
triumphant
delighted
jolly
trustworthy
delightful
jovial
trusty
dependable
joyful
truthful
devoted
joyous
uplifting
docile
jubilant
victorious
dynamic
keen
vigorous
eager
kind
virtuous
earnest
laudable
vivacious
easygoing
laughing
whimsical
ebullient
likable
witty
ecstatic
lively
wonderful
elated
lovely
worthy
emphatic
loving
zealous
enchanting
loyal
zestful
`
